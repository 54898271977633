import axios from 'axios'
import * as localData from '@/services/localData.js'
import Vue from 'vue'
import { postData } from "@/services/api";


const state = () => ({
  status: '',
  auth_token: localData.getlocalToken('auth_token') || '',
  refresh_token:localData.getlocalToken('refresh_token') || '',
  username : localData.getlocalToken('username') || '',
  user_id : localData.getlocalToken('user_id') || '',
  is_admin: localData.getlocalToken('is_admin') || '',
  first_name: localData.getlocalToken('first_name') || '',
  last_name: localData.getlocalToken('last_name') || '',
  email: localData.getlocalToken('email') || '',
})

const mutations = {
  auth_request(state){
    state.status = 'loading'
  },
  auth_success(state, data){
    state.status = 'success'
    state.auth_token = data.auth_token
    state.refresh_token = data.refresh_token
    state.username = data.username
    state.user_id = data.user_id
    state.is_admin = data.is_admin
    state.first_name = data.first_name
    state.last_name = data.last_name
    state.email = data.email
  },
  auth_error(state){
    state.status = 'error'
  },
  logout(state){
    state.status = ''
    state.auth_token = ''
    state.refresh_token = ''
    state.username = ''
    state.user_id = ''
    state.is_admin = ''
    state.first_name = ''
    state.last_name = ''
    state.email = ''
  },
  auth_validate(state, auth_token){
    state.status = 'success'
    state.auth_token = auth_token
  }
}

const actions = {
  login({commit}, user){
    return new Promise((resolve, reject) => {
      commit('auth_request')
      postData('auth-mardigital/login/',user)
      .then(resp => {
        if(resp.data.access){
          const data = {
            auth_token : resp.data.access,
            refresh_token : resp.data.refresh,
            username : resp.data.username,
            user_id : resp.data.id,
            is_admin : resp.data.is_admin,
            first_name : resp.data.first_name,
            last_name : resp.data.last_name,
            email : resp.data.email,
          }
          localData.setlocalToken('auth_token',data.auth_token)
          localData.setlocal('refresh_token',data.refresh_token)
          localData.setlocal('username',data.username)
          localData.setlocal('user_id',data.user_id)
          localData.setlocal('is_admin',data.is_admin)
          localData.setlocal('first_name',data.first_name)
          localData.setlocal('last_name',data.last_name)
          localData.setlocal('email',data.email)

          commit('auth_success',data)
          Vue.noty.success('Log-in correcto en Mar Digital');
          resolve(resp)
        }
        else{
          commit('auth_error')
          localData.deleteAll()
          Vue.noty.error('Usuario o contraseña incorrectos')
        }
      })
      .catch(error => {
        commit('auth_error')
        Vue.noty.error('Usuario o contraseña incorrectos')
        localData.deleteAll()
        reject(error)
      })
    })
  },
  refresh_token({commit}){
    if (this.state.user.refresh_token){
      return new Promise((resolve, reject) => {
        postData('auth-jwt/refresh/',{refresh:this.state.user.refresh_token})
        .then(resp => {
          localData.setlocalToken('auth_token',resp.data.access)
          commit('auth_validate',resp.data.access)
        })
        .catch(error => {
          localData.deleteAll()
          commit('logout')
          //Vue.router.push('/')
          Vue.noty.error('Sesión expirada')
        })
      })
    }else {
      commit('logout')
    }
  },
  logout({commit}){
    return new Promise((resolve, reject) => {
      commit('logout')
      localData.deleteAll()
      Vue.noty.success("Log out correcto")
      //Vue.router.push('/')
      resolve('OK')
    })
  }
}

 const getters = {
    isLoggedIn: state => !!state.auth_token,
    authStatus: state => state.status,
    token: state => state.auth_token,
    username: state => state.username,
    user_id: state => state.user_id,
    is_admin: state => state.is_admin,
    first_name: state => state.first_name,
    last_name: state => state.last_name,
    email: state => state.email,
  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
<template>
  <div class="home-container">
    <div class="home-title">Mar Digital</div>
    <div class="home-title-2">Bienvenidos</div>

    <div class="home-text">La Región de Murcia dispone de un rico patrimonio natural de enorme atractivo, en muchos casos poco conocido por el turista nacional e internacional. Con este proyecto se pretende dar a conocerlo, usando para ello las nuevas herramientas de digitalización con las que el visitante pueda conocer los singulares espacios naturales. Entre estas herramientas de digitalización se encuentran los visores de sistemas de información geográfica, sistemas de realidad virtual, drones de campo, entre otras, que permiten crear gemelos digitales de estos recursos turísticos que a su vez pueden habilitar nuevos modelos de negocio con los que fomentar el turismo en la Región de Murcia.</div>
    <img src="@/assets/images/image_home_1.jpg" style="width: 60%; margin-top: 35px;" />
  </div>
</template>
<script>


export default {
  name: 'HomeView',
  components: {

  }
}
</script>

<style lang = "scss" scoped>
  .home-container {
    padding: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-title {
    color: #2d6899;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .home-title-2 {
    color: #2d6899;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .home-text {
    width: 60%;
    text-align: justify;
  }
</style>
<template>
  <div class="viewer-container">
      <iframe class="viewer-frame" src="https://mardigital-viewer.ctnaval.com" frameborder="0"></iframe>
      <div class="text-legend">Botón derecho para rotar escena</div>
  </div>
</template>

<script>
export default {
  name: "viewer",
};
</script>

<style scoped lang="scss">
  .viewer-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .viewer-frame {
    overflow:hidden;
    width: 970px;
    height: 610px;
  }
  .text-legend {
    color: grey;
    margin-top: 18px;
  }
</style>
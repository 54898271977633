import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import router from './router'
import axios from 'axios'
import 'vuejs-noty/dist/vuejs-noty.css'
import VueNoty from 'vuejs-noty'
import Vuelidate from 'vuelidate'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import * as localData from '@/services/localData.js'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(VueNoty)

Vue.config.productionTip = false

new Vue({
  beforeCreate () {
    Vue.prototype.$http = axios
    axios.defaults.headers.common['Authorization'] = "Bearer  " + localData.getlocalToken('auth_token')
    //axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
    //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'
    axios.defaults.xsrfCookieName = 'csrftoken' 
  },
  router,
  store,
  localData,
  render: h => h(App)
}).$mount('#app')

const auth_token = localData.getlocalToken('auth_token')
if (auth_token) {
  localData.setlocalToken('auth_token',auth_token)
}
<template>
  <div class="download-container">
    <div class="download-title">Descarga de datos</div>
    <div class="download-title-2">Archivos origen de datos del proyecto</div>
    <table>
      <tr>
        <th>Archivo</th>
        <th>Tamaño</th>
        <th></th>
      </tr>
      <tr v-for="(file, index) in files" v-bind:key="index">
        <td>{{ file[0] }}</td>
        <td>{{ file[1] }}</td>
        <td><span class="file-in-list"><a :href="server_path + file[0]" download="download">Descarga</a></span></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { getData, downloadFileRequest, downloadFileStream } from  '@/services/api'

export default {
  name: 'Download',
  data() {
    return {
      files: [],
      server_path: ''
    };
  },
  mounted () {
    this.server_path = process.env.VUE_APP_HTTP_S + '://' + process.env.VUE_APP_API_HOST + ':' + process.env.VUE_APP_API_PORT + '/api/download/download/'
    this.getList()
  },
  methods: {
    getList: function() {
      let url = "download/list/"
      getData(url).then(res => {
        this.files = res.data.files
      })
    },
    downloadFile: function(fileName) {
      let url ="download/"
      let formData = new FormData()
      formData.append('fileName', fileName)
      // downloadFileRequest(url, formData, fileName)
      // .then((res) => {})
      downloadFileStream(url, fileName)
    }
  }
};
</script>

<style lang = "scss" scoped>
  .download-container {
    padding: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .download-title {
    color: #2d6899;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .download-title-2 {
    color: #2d6899;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .file-in-list {
    cursor: pointer;
    color: #08f;
  }

  /* TABLA */
  td, th {
    padding: 20px;
  }
  th {
    background-color: #2d6899;
    color: white;
  }
  tr:nth-child(even){background-color: #e1e9f2;}
</style>


import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DownloadView from '../views/DownloadView.vue'
import MapView  from '../views/MapView.vue'
import Viewer  from '../views/Viewer.vue'
import login from '../components/user/login.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: "/login",
    name: "Login",
    component: login,
    meta: {
      requiresNotAuth: true,
    },
  },
  {
    path: '/download',
    name: 'download',
    component: DownloadView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/map',
    name: 'map',
    component: MapView,
  },
  {
    path: '/viewer',
    name: 'viewer',
    component: Viewer,
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresNotAuth)) {
    if (!store.getters['user/isLoggedIn']) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['user/isLoggedIn']) {
      next()
      return
    }
    next('login')
  } else {
    next()
  }
})

export default router

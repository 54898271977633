<template>
  <footer>
    © 2022 Mar digital

    <div style="flex: 1;"></div>

    <img :src="require('@/assets/images/logo_mur.jpg')" class="mr-4" alt="Comisión Europea Logo" style="height: 45px; margin-right: 40px;"/>
    <img :src="require('@/assets/images/logo_eu_white.svg')" class="mr-4" alt="Comisión Europea Logo" style="height: 45px; margin-right: 40px;"/>
    <img :src="require('@/assets/images/ctn_logo_white.svg')" class="mr-4" alt="CTN Logo" style="height: 30px;"/>
  </footer>
</template>

<script>
export default {
  name: 'marFooter',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

  footer{
    width: 100%;
    /*position: absolute;*/
    bottom: 0;
    left: 0;
    background: $footer-default;
    color: $footer-text-default;
    height: $footer-height;
    padding: 0rem 2rem 0rem 2rem;
    display:flex;
    flex-direction: row;
    align-items: center;
  }

</style>

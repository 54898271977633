<template>
  <div class="view-container">
    <div id="view-div" ref="map"></div>
    <div id="topbar">
      <button
        class="action-button esri-icon-measure-line"
        id="distanceButton"
        type="button"
        title="Measure distance between two points"
      ></button>
      <button class="action-button esri-icon-measure-area" id="areaButton" type="button" title="Measure area"></button>
    </div>
  </div>
</template>

<script>

// ArcGIS API JS
import { loadModules } from 'esri-loader'

export default {
  name: "Map",
  data(){
    return {
      basemap: null,
      map: null,
      view: null,
      activeWidget: null
    }
  },
  mounted(){
    this.setMap()
  },
  methods:{
    setMap: function() {
      loadModules(['esri/views/SceneView', 'esri/WebScene','esri/widgets/LayerList','esri/layers/GraphicsLayer', 'esri/Graphic', 'esri/widgets/Legend'], {css: true})
      .then(([SceneView, WebScene, LayerList, GraphicsLayer, Graphic, Legend]) => {

        /*************************
        // 3D SCENE
        *************************/
        const scene = new WebScene({
          portalItem: { 
            // autocasts as new PortalItem()
            id: "f51ff929fd7d4858972753ec9b6569e5"  // ID of the WebScene on arcgis.com
          }
        });

        this.view = new SceneView({
          map: scene,  // The WebScene instance created above
          container: "view-div",
        });

        this.view.constraints = {
          minZomm : 9
        }

        /*************************
        // MEASUREMENT WIDGET
        *************************/
        this.activeWidget = null;
        // add the toolbar for the measurement widgets
        this.view.ui.add("topbar", "top-left");

        document.getElementById("distanceButton").addEventListener("click", (event) => {
          this.setActiveWidget(null);
          if (!event.target.classList.contains("active")) {
            this.setActiveWidget("distance");
          } else {
            this.setActiveButton(null);
          }
        });

        document.getElementById("areaButton").addEventListener("click", (event) => {
          this.setActiveWidget(null);
          if (!event.target.classList.contains("active")) {
            this.setActiveWidget("area");
          } else {
            this.setActiveButton(null);
          }
        });

        
        /*************************
         * LAYER LIST WIDGET
        *************************/
        this.view.when(() => {
          const layerList = new LayerList({
            view: this.view,
            listItemCreatedFunction: (event) => {
              const item = event.item;
              if(item.title === "Batimetría" || item.title === "Batimetría (Análisis)" || item.title === "Curvas de nivel (1m)" || item.title === "Color"){
                if (item.layer.allSublayers && item.layer.allSublayers.length > 0) {
                  item.layer.allSublayers.forEach(function (item2) {
                    item2.listMode = "hide";
                  });           
                }
              } 
              else if(item.title === "")   {
                item.layer.listMode = "hide";
              }
              // item.panel = {
              //   content: "legend",
              //   open: true
              // }; 
            }
          });

          // Add widget to the top right corner of the view
          this.view.ui.add(layerList, "top-right");       
        });


        let legend = new Legend({
          view: this.view
        });
        this.view.ui.add(legend, "bottom-right");

        

        // this.view.when(() => {
        //   const layerList = new LayerList({
        //     view: this.view,
        //     listItemCreatedFunction: (event) => {
        //       const item = event.item;
        //       if(item.title === "TODO_CAPAS_WTL1"){
        //         item.title = "Capas de elevación"
        //       }
        //       if(item.title === ""){
        //         item.title = "Fuerte de Navidad"
        //       }
        //       item.panel = {
        //         content: "legend",
        //         open: true
        //       };           
        //       this.view.ui.add(layerList, "top-right");
        //     }
        //   });

        //   layerList.visibleElements = {
        //     statusIndicators: false
        //   };
        // });







        const graphicsLayer = new GraphicsLayer();
        scene.add(graphicsLayer);

        /*************************
         * Add a 3D point graphic
         *************************/

        // Faro
        const point = {
          type: "point", // autocasts as new Point()
          x: -0.9856659,
          y: 37.5856942,
          z: 50
        };

        const markerSymbol = {
          type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
          color: [255, 0, 0],
          size: "30px",
          outline: {
            // autocasts as new SimpleLineSymbol()
            color: [255, 255, 255],
            width: 2
          }
        };

        const attributes = {
          Name: 'Fuerte de Navidad',
          index: 1
        }

        const popupTemplate = {
          title: "{Name}",
          content: "El Fuerte de Navidad protegía la entrada cruzando sus fuegos con las baterías de Santa Ana y de Trincabotijas. <a href='https://puertodeculturas.cartagena.es/ficha_fuerte_navidad.asp' style='color: blue;'>Más información</a>",
          actions: [{
              title: "Ver en 3D",
              id: 'view_3d',
              index: 1,
          }],
        }
        let globalThis = this
        this.view.popup.on("trigger-action", function(event) {
          if(event.action.id == 'view_3d') {
            globalThis.$router.push("viewer")
          }
        })

        const pointGraphic = new Graphic({
          geometry: point,
          symbol: markerSymbol,
          attributes: attributes,
          popupTemplate: popupTemplate
        });

        graphicsLayer.add(pointGraphic);
        
        const polyline = {
          type: "polyline", // autocasts as new Polyline()
          paths: [[-0.9856659, 37.5856942, 50], [-0.9856659, 37.5856942, 0]]
        };

        const lineSymbol = {
          type: "simple-line", // autocasts as SimpleLineSymbol()
          color: [255, 0, 0],
          width: 2
        };

        const polylineGraphic = new Graphic({
          geometry: polyline,
          symbol: lineSymbol,
          attributes: attributes,
          popupTemplate: popupTemplate
        });

        graphicsLayer.add(polylineGraphic);

        // POINTER CURSOR OVER MARKER
        this.view.on("pointer-move", function (event) {
          var screenPoint = {
            x: event.x,
            y: event.y,
          };
          // Search for graphics at the clicked location
          globalThis.view.hitTest(screenPoint).then(function (response) {

            if (response.results.length) {
              globalThis.$refs.map.style.cursor = "pointer"
            }
            else {
              globalThis.$refs.map.style.cursor = "initial"
            }

          })
        })

      });
    },

    setActiveWidget: function (type) {
      loadModules(['esri/widgets/DirectLineMeasurement3D', 'esri/widgets/AreaMeasurement3D', 'esri/core/promiseUtils'], {css: true})
      .then(([DirectLineMeasurement3D, AreaMeasurement3D, promiseUtils]) => {
        switch (type) {
          case "distance":
            this.activeWidget = new DirectLineMeasurement3D({
              view: this.view
            });

            // skip the initial 'new measurement' button
            this.activeWidget.viewModel.start().catch((error) => {
              if (promiseUtils.isAbortError(error)) {
                return; // don't display abort errors
              }
              throw error; // throw other errors since they are of interest
            });

            this.view.ui.add(this.activeWidget, "top-left");
            this.setActiveButton(document.getElementById("distanceButton"));
            break;
          case "area":
            this.activeWidget = new AreaMeasurement3D({
              view: this.view
            });

            // skip the initial 'new measurement' button
            this.activeWidget.viewModel.start().catch((error) => {
              if (promiseUtils.isAbortError(error)) {
                return; // don't display abort errors
              }
              throw error; // throw other errors since they are of interest
            });

            this.view.ui.add(this.activeWidget, "top-left");
            this.setActiveButton(document.getElementById("areaButton"));
            break;
          case null:
            if (this.activeWidget) {
              this.view.ui.remove(this.activeWidget);
              this.activeWidget.destroy();
              this.activeWidget = null;
            }
            break;
        }
      })
    },

    setActiveButton: function (selectedButton) {
      // focus the view to activate keyboard shortcuts for sketching
      this.view.focus();
      const elements = document.getElementsByClassName("active");
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove("active");
      }
      if (selectedButton) {
        selectedButton.classList.add("active");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.map-container{
  z-index:0 !important;
  width: 100%;
  height: 94vh;   
}

#view-div, .view-container {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* MEASUREMENT WIDGET */
#topbar {
  background: #fff;
  padding: 10px;
}

.action-button {
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #d3d3d3;
  color: #6e6e6e;
  height: 32px;
  width: 32px;
  text-align: center;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}

.action-button:hover,
.action-button:focus {
  background: #0079c1;
  color: #e4e4e4;
}

.active {
  background: #0079c1;
  color: #e4e4e4;
}
</style>
<template>
  <div class="map-view">
      <Map/>
  </div>
</template>

<script>
// import FooterSmall from "@/components/Footer/Small/FooterSmall.vue"; 
import Map from "@/components/map.vue"
// import MapSidebar from "@/components/MapSidebar/MapSidebar.vue"; 
export default {
  name: "mapviews",
  components: {
    Map,
  }, 
  data() {
    return {

    };
  } 
};
</script>

<style scoped lang="scss">
  .map-view {
    width: 100%;
    height: 100%;
  }
  .map-frame {
    overflow:hidden;
    height:100%;
    width:100%;
  }
</style>
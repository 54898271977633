import axios from 'axios'

const server_path = process.env.VUE_APP_HTTP_S + '://' + process.env.VUE_APP_API_HOST + ':' + process.env.VUE_APP_API_PORT + '/api/';

////////////////// Generic Functions ////////////////////////

export function getData(url) {
  return axios
    .get(server_path + url)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}

export function postData(url, data) {
  return axios
    .post(server_path + url, data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}

export function putData(url, id, data) {
  return axios
    .put(server_path + url + id + "/", data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}

export function deleteData(url, id) {
  return axios
    .delete(server_path + url + id + "/")
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}

export function downloadFileRequest(url, data, fileName) {
  return axios
    .get(server_path + url + 'download/' + fileName, { responseType: "blob" })
    .then(res => {
      const blob = new Blob([res.data])
      const objectUrl = URL.createObjectURL(blob)

      const link = document.createElement('a');
      link.href = objectUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(objectUrl);

      return 'ok'
    })
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}





export async function downloadFileStream (url, fileName) {
  return axios
  .get(server_path + url + 'download/' + fileName, { responseType: "stream" })
  .then(res => {
    let blob = res.data
    let reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = (e) => {
     let a = document.createElement('a')
     a.download = fileName
     a.href = e.target.result
     document.body.appendChild(a)
     a.click()
     document.body.removeChild(a)
    }
  })   
}



<template>
  <form class="form-login" @submit.prevent="login">
    <div class="form-group row">
      <div class="col-12 text-center">
        <label for="Login info">Utiliza tus credenciales para acceder</label>
      </div>
      <!-- input-group.// -->
    </div>
    <div class="form-group row pb-1 mt-4">
      <div class="col-12 pl-0">
        <img class ='icons-login'
            :src="require('@/assets/icons/icon-user.svg')"
            alt="user icon"
        />
        <input
          class="form-generic"
          required
          v-model="username"
          type="name"
          placeholder="Usuario"
        />
      </div>
      <!-- input-group.// -->
    </div>
    <!-- form-group// -->
    <div class="form-group row pb-2">
      <div class="col-12 pl-0">
        <img class ='icons-login'
            :src="require('@/assets/icons/icon-password.svg')"
            alt="password icon"
        />
        <input
          class="form-generic"
          required
          v-model="password"
          type="password"
          placeholder="Password"
        />
      </div>
      <!-- input-group.// -->
    </div>
    <!-- form-group// -->
    <div class="form-group row mt-4">
      <div class="col-12 mx-auto fit-btn-position text-center">
        <button type="submit" class="btn btn-outline-secondary">
          LOGIN
        </button>
      </div>
       <!-- <a  class="text-center mt-1 pt-3 link-1 w-100" v-on:click = "$parent.viewComponent = 'forgot'" >I forgot my password</a> -->
       <!-- <a  class="text-center  pt-3 link-1 w-100" >Registro de nuevo usuario</a> -->
    </div>
  </form>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      username: "",
      password: ""
    };
  },
  mounted () {

  },
  methods: {
    login: function() {
      var userdata = {
          username: this.username,
          password: this.password
      }
      this.$store
        .dispatch("user/login", userdata)
        .then(() => this.$router.push("/download"))
        .catch(err => console.log(err));
    }
  }
};
</script>

<style lang = "scss" scoped>

.form-login {
    margin-top: 8%;
    margin-left: 35%;
    margin-right: 35%;
}

</style>

<template>
  <div class="nav-bar-mar-digital">
    <div class="logo-mar-digital"></div>
    <b-nav card-header pills align="center" style=" flex: 1;">
      <b-nav-item to="/" exact exact-active-class="active">Home</b-nav-item>
      <b-nav-item to="/map" exact exact-active-class="active">Mapa</b-nav-item>
      <b-nav-item to="/download" exact exact-active-class="active">Descarga</b-nav-item>
    </b-nav>
    
    <div class="right-nav-container">
      <div v-if="isLoggedIn" class="log-out-button" @click="logout">Log out</div> 
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "marHeader",
  data() {
    return {
    };
  },
  computed: { 
    ...mapGetters("user",{
      isLoggedIn: 'isLoggedIn'
    }),
    currentRouteName() {
      return this.$route.name;
    }
  },
  mounted () {
  },
  methods: {
    logout: function() {
      this.$store
        .dispatch("user/logout")
        .then(() => this.$router.push("/"))
        .catch(err => console.log(err));
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  .nav-bar-mar-digital {
    display: flex;
    flex-direction: row;
    height: 70px;
    align-items: center;
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.3);
    position: relative;
    z-index: 100000;
    background-color: #2d6899;
  }
  .logo-mar-digital {
    width: 220px;
    height: 40px;
    margin-left: 40px;
    background-image: url('~@/assets/logo_mar_digital.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
  .right-nav-container {
    display:flex;
    justify-content: right;
    align-items: center;
    margin-right: 40px;
    width: 220px;
  }
  .log-out-button {
    cursor: pointer;
    color: rgb(169, 199, 243);
  }
  .log-out-button:hover {
    color: white;
  }
  .nav-pills .nav-link.active {
    background-color: #688bbf;
  }
</style>

<template>
  <div id="app">
    <marHeader></marHeader>
      <router-view style="flex: 1;"></router-view>
    <martionFooter></martionFooter>
  </div>
</template>

<script>

import marHeader from '@/components/header'
import martionFooter from '@/components/footer'

export default {
  name: 'App',
  components: {
    marHeader,
    martionFooter
  }
}
</script>

<style lang="scss">
#app {
  width:100%; 
  height:100%; 
  position:fixed; 
  left:0;
  top:0;
  overflow:hidden;
  display: flex;
  flex-direction: column;
}
</style>

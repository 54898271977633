import axios from 'axios'

const getlocalToken = (key) => {
    return localStorage.getItem(key)
}

const setlocalToken = (key, value) => {
    localStorage.setItem(key, value)
    axios.defaults.headers.common['Authorization'] = "Bearer  " + value
}

const setlocal = (key, value) => {
    localStorage.setItem(key, value)
}

const deletelocalToken = (key) => {
    localStorage.removeItem(key)
    delete axios.defaults.headers.common['Authorization']
};

const deleteAll = () => {
    localStorage.clear();
    delete axios.defaults.headers.common['Authorization']
};

export {
    getlocalToken,
    setlocalToken,
    deletelocalToken,
    setlocal,
    deleteAll
}